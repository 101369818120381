"use client";

import NavBar from "@public-sites/unified-header/dist/main";
import { NavLogoKind } from "@public-sites/unified-header/dist/types";
import { Metadata } from "next";
import { Covered_By_Your_Grace, Gelasio, Lora, Raleway } from "next/font/google";
import { Suspense } from "react";
import Footer from "~/components/layout/footer";
import PrivacyBanner from "~/components/privacyBanner/main";
import layoutMessages from "~/resources/messages/en-us/app/layout.json";
import Console from "./console";
import EnforceHttps from "./enforceHttps";
import ErrorMessageComponent from "./errorMessage";
import Providers from "./providers";
import ToTop from "./toTop";
import Track from "./track";

const ralewayMedium = Raleway({
	subsets: ["latin"],
	variable: "--font-raleway",
});

const coveredByYourGraceMedium = Covered_By_Your_Grace({
	subsets: ["latin"],
	variable: "--font-covered-by-your-grace",
	weight: "400",
});

const gelasio = Gelasio({
	subsets: ["latin"],
	variable: "--font-gelasio",
	weight: ["400", "500", "600", "700"],
});

const lora = Lora({
	subsets: ["latin"],
	variable: "--font-lora",
	style: "italic",
});

export const metadata: Metadata = {
	title: layoutMessages.layout.defaultPageTitle.value,
	description: layoutMessages.layout.defaultPageDescription.value,
	openGraph: {
		title: layoutMessages.layout.defaultPageTitle.value,
		description: layoutMessages.layout.defaultPageDescription.value,
	},
	metadataBase: new URL(process.env.NEXT_PUBLIC_BASE_SITE_URL ?? "https://localhost:3500"),
};

export default function ErrorPage() {
	return (
		<html id="page_layout_base">
			<body>
				<Suspense fallback={<div></div>}>
					<Track />
					<Console />
					<ToTop />
					<EnforceHttps />
				</Suspense>
				<main
					className={`${ralewayMedium.variable} ${lora.variable} ${coveredByYourGraceMedium.variable} ${gelasio.variable} font-primary text-sm h-full`}
				>
					<div className="flex flex-col items-stretch h-screen mx-auto">
						<Providers>
							<Suspense fallback={<div></div>}>
								<NavBar
									siteIdentityLinkLogo={NavLogoKind.Epic}
									siteIdentityLinkTooltip={layoutMessages.layout.siteIdentityLinkTooltip.value}
									topItems={[
										{
											navLogoKind: NavLogoKind.Epic,
											selected: true,
											tooltip: layoutMessages.layout.epicLogoTooltip.value,
										},
										{
											navLogoKind: NavLogoKind.EpicShare,
											tooltip: layoutMessages.layout.epicshareLogoTooltip.value,
										},
										{
											navLogoKind: NavLogoKind.EpicResearch,
											tooltip: layoutMessages.layout.epicresearchLogoTooltip.value,
										},
										{
											navLogoKind: NavLogoKind.Cosmos,
											tooltip: layoutMessages.layout.cosmosLogoTooltip.value,
										},
										{
											navLogoKind: NavLogoKind.MyChart,
											tooltip: layoutMessages.layout.mychartLogoTooltip.value,
										},
										{
											navLogoKind: NavLogoKind.OpenDotEpic,
											tooltip: layoutMessages.layout.openDotEpicLogoTooltip.value,
										},
										{
											navLogoKind: NavLogoKind.UserWeb,
											tooltip: layoutMessages.layout.userwebLogoTooltip.value,
										},
										{
											navLogoKind: NavLogoKind.Showroom,
											tooltip: layoutMessages.layout.showroomLogoTooltip.value,
										},
									]}
									bottomItems={[
										{
											href: "/software",
											label: layoutMessages.layout.softwareLinkCaption.value,
											tooltip: layoutMessages.layout.softwareLinkCaption.value,
										},
										{
											label: layoutMessages.layout.aboutUsSubmenuItemCaption.value,
											tooltip: layoutMessages.layout.aboutUsSubmenuItemTooltip.value,
											subItems: [
												{
													href: "/about",
													label: layoutMessages.layout.ourStoryLinkCaption.value,
													tooltip: layoutMessages.layout.ourStoryLinkTooltip.value,
												},
												{
													href: "/visiting",
													label: layoutMessages.layout.visitingLinkCaption.value,
													tooltip: layoutMessages.layout.visitingLinkTooltip.value,
												},
												{
													href: "/community",
													label: layoutMessages.layout.communityLinkCaption.value,
													tooltip: layoutMessages.layout.communityLinkTooltip.value,
												},
												{
													href: "/dei",
													label: layoutMessages.layout.deiLinkCaption.value,
													tooltip: layoutMessages.layout.deiLinkTooltip.value,
												},
												{
													href: "/sustainability",
													label: layoutMessages.layout.sustainabilityLinkCaption.value,
													tooltip: layoutMessages.layout.sustainabilityLinkTooltip.value,
												},
												{
													href: "/contact",
													label: layoutMessages.layout.contactLinkCaption.value,
													tooltip: layoutMessages.layout.contactLinkTooltip.value,
												},
											],
										},
										{
											href: "/epic/newsroom",
											label: layoutMessages.layout.newsroomLinkCaption.value,
											tooltip: layoutMessages.layout.newsroomLinkTooltip.value,
										},
									]}
								/>
								<ErrorMessageComponent>
									<div>
										<span>
											{"Something went wrong? You "}
											<i>{"calf"}</i>
											{" to be kidding. "}
										</span>
										<span>{"We'll have it fixed in no time."}</span>
									</div>
								</ErrorMessageComponent>
								<PrivacyBanner privacyPageUrl="/privacypolicies" siteKey="epicdotcom" />
								<Footer />
							</Suspense>
						</Providers>
					</div>
				</main>
			</body>
		</html>
	);
}
